<script setup lang="ts">
import { onMounted, ref } from "vue"
import SecondaryButton from "../utils/SecondaryButton.vue"
import { Autoplay } from "swiper/modules"
import Swiper from "swiper"
import getDeepdiveUrl from "@/lib/getDeepdiveUrl"
import { supportedExchanges } from "@/lib/external-data/supportedExchanges"
import useAosRefresh from "@/composables/useAosRefresh"
import {useI18n} from "vue-i18n";
const {t} = useI18n({
  useScope: 'global'
});

interface Props {
  loading?: HTMLImageElement['loading']
}

const props = withDefaults(defineProps<Props>(), {
  loading: 'lazy'
})

const swiperElRef = ref<HTMLDivElement>();

useAosRefresh()

onMounted(() => {
  const swiperEl = swiperElRef.value;
  if (!swiperEl) return;

  new Swiper(swiperEl, {
    modules: [Autoplay],
    loop: true,
    slidesPerView: "auto",
    // centeredSlides: true,
    allowTouchMove: false,
    speed: 10000,
    autoplay: {
      delay: 0,
      disableOnInteraction: false,
    },
  });

})

</script>

<template>
  <section
    data-aos
    class="relative w-full flex justify-between items-center h-45 sm:h-25 container max-w-240 inset-0 duration-700 ease-in-out [&:not(.aos-animate)]:(opacity-0)"
  >
    <div class="relative w-full">
      <div
        ref="swiperElRef"
        class="swiper overflow-hidden w-full fade-x-16 sm:fade-x-32"
      >
        <div class="swiper-wrapper flex w-max space-x-12 ease-linear">
          <div
            v-for="({ name, label }, index) in supportedExchanges"
            :key="index"
            class="swiper-slide flex"
          >
            <img 
              :src="`/exchanges/logos/${name}.svg`" 
              class="h-7"
              :alt="label" 
              :loading="props.loading"
              height="28"
            />
          </div>
        </div>
      </div>
      <div
        :class="[
          'absolute translate-y-full sm:bottom-auto z-50 bg-brand-background rounded-full',
          'sm:(right-0 top-1/2 -translate-y-1/2) lt-sm:(left-1/2 -translate-x-1/2)',
        ]"
      >
        <SecondaryButton
          :href="getDeepdiveUrl('supported-exchanges')"
          :showArrow="false"
        >
          {{ t('view_supported_platform') }}
        </SecondaryButton>
      </div>
    </div>
  </section>
</template>